import React from "react"
import { graphql, Link } from "gatsby"
import { kebabCase } from "lodash"

export default function BlogPostTemplate({ data }) {
	return (
		<div>
			<h1>blog post template</h1>
			<h1 className="title is-size-2 has-text-weight-bold is-bold-light">{data.markdownRemark.frontmatter.title}</h1>
			<p>{data.markdownRemark.frontmatter.description}</p>
			{data.markdownRemark.frontmatter.tags && data.markdownRemark.frontmatter.tags.length ? (
				<div style={{ marginTop: `4rem` }}>
					<h4>Tags</h4>
					<ul className="taglist">
						{data.markdownRemark.frontmatter.tags.map((tag) => (
							<li key={tag + `tag`}>
								<Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
							</li>
						))}
					</ul>
				</div>
			) : null}
			<div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
		</div>
	)
}

export const pageQuery = graphql`
	query BlogPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description
				tags
			}
		}
	}
`
